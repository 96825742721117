
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import IUserRequest from "@/services/models/user/IUserRequest";
import IStateFederation from "@/services/models/IStateFederation";
// import IProfessionalDocumentType from "@/services/models/IProfessionlaDocumentType";
import ICity from "@/services/models/ICity";
import { required, email } from "vuelidate/lib/validators";
import {
  UserInsertRequest,
  StateFederation,
  CityState,
  UserUpdateRequest,
  UserFetchOneRequest,
  UserGenders,
  UserMaritalStatus,
  UserTypes,
  UserStatus,
  UserAuthorizations,
  UserRegionals,
} from "@/services/api/ApiUser";
import {
  notificationSuccess,
  notificationError,
} from "@/utils/NotificationUtils";
import {
  IUserAuthorizations,
  IUserGender,
  IUserTypes,
  IUserStatus,
  IRegional,
} from "@/services/models";

import IUserMaritalStatus from "@/services/models/user/IUserMaritalStatus";
import { UserListToPostRequest } from "@/services/transform/UserDataTransform";

@Component({
  validations: {
    form: {
      name: { required },
      surname: { required },
      cpf: { required },
      email: { required, email },
      birthdate: { required },
      rg: { required },
      social_name: { required },
      mobile_phone: { required },
      marital_status_id: { required },
      gender_id: { required },
      type_id: { required },
      status_id: { required },
      street_name: { required },
      post_code: { required },
      building_number: { required },
      complement: { required },
      city_id: { required },
      neighborhood: { required },
      state_id: { required },
      authorizations: { required },
      regional_id: { required },
    },
  },
})
export default class UserForm extends Vue {
  @Prop() open_modal!: boolean;
  @Prop() user_id!: number;

  loading = false;
  authorizationsOptions: IUserAuthorizations[] = [];

  authorizationsOptionsSelect: IUserAuthorizations[] = [];

  genderOptions: IUserGender[] = [];
  maritalStatusOptions: IUserMaritalStatus[] = [];
  userTypesOptions: IUserTypes[] = [];
  userStatusOptions: IUserStatus[] = [];

  // optionsTypeDocument: IProfessionalDocumentType[] = [];
  optionsState: IStateFederation[] = [];
  optionsCity: ICity[] = [];
  optionsRegionals: IRegional[] = [];

  form: IUserRequest = {
    id: undefined,
    name: "",
    surname: "",
    social_name: "",
    email: "",
    gender_id: undefined,
    birthdate: "",
    cpf: "",
    rg: "",
    mobile_phone: "",
    status_id: undefined,
    marital_status_id: undefined,
    type_id: undefined,
    street_name: "",
    building_number: "",
    post_code: "",
    complement: "",
    neighborhood: "",
    city_id: undefined,
    state_id: undefined,
    country_id: 1,
    authorizations: [],
    // regional: undefined,
    regional_id: undefined,
  };

  async requestGender(): Promise<void> {
    let gender = await UserGenders();
    this.genderOptions = gender;
  }
  async requestMaritalStatus(): Promise<void> {
    let marital_status = await UserMaritalStatus();
    this.maritalStatusOptions = marital_status;
  }
  async requestTypes(): Promise<void> {
    let types = await UserTypes();
    this.userTypesOptions = types;
  }
  async requestStatus(): Promise<void> {
    let status = await UserStatus();
    this.userStatusOptions = status;
  }

  async requestState(): Promise<void> {
    let state = await StateFederation();
    this.optionsState = state;
  }

  async requestAuthorizations(): Promise<void> {
    let authorizations = await UserAuthorizations();
    this.authorizationsOptionsSelect = authorizations;
  }

  async changeState(id: number): Promise<void> {
    let city = await CityState(id);
    this.optionsCity = city;

    // let currentState = this.optionsState.filter(
    //   (state) => state.id == this.form.state_id
    // );
    // let [region_id] = currentState;
    // console.log(region_id);
    // this.form.regional_id = region_id.region_id;
    // console.log(city);
  }
  // async changeRegion(state) {
  //   console.log(state.id);
  // }

  //  async changeAuthorizations(id: number): Promise<void> {
  //   let city = await CityState(id);
  //   this.optionsCity = city;
  // }
  async requestRegionals(): Promise<void> {
    let regionals = await UserRegionals();
    this.optionsRegionals = regionals;
  }

  close(): void {
    this.$emit("close_modal");
    this.$v.$reset();
    if (!this.user_id) {
      this.reset();
    }
  }

  removeCaracter(value: string): string {
    return value.replace(/[^\d]/g, "");
  }

  async edit(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        if (this.form.id) {
          let data = {
            ...this.form,
            cpf: this.removeCaracter(this.form.cpf),
            mobile_phone: this.removeCaracter(this.form.mobile_phone),
            post_code: this.removeCaracter(this.form.post_code),
          };
          await UserUpdateRequest(this.form.id, data);
          this.close();
          notificationSuccess(
            `${this.$t("users.messages.success.message.update")}`
          );
          this.$emit("reload-page");
        }
      } catch (error) {
        console.error(error);
        notificationError(`${this.$t("users.messages.error.message.update")}`);
      }
    }
  }

  async save(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        let data = {
          ...this.form,
          // hospital_id: 124,
          regional_id: 1,

          cpf: this.removeCaracter(this.form.cpf),
          mobile_phone: this.removeCaracter(this.form.mobile_phone),
          post_code: this.removeCaracter(this.form.post_code),
        };
        await UserInsertRequest(data);
        this.close();
        notificationSuccess(
          `${this.$t("users.messages.success.message.create")}`
        );
        this.$emit("reload-page");
      } catch (error) {
        console.error(error);
        notificationError(`${this.$t("users.messages.error.message.create")}`);
      }
    }
  }

  reset(): void {
    this.form = {
      id: undefined,
      name: "",
      surname: "",
      social_name: "",
      email: "",
      gender_id: undefined,
      birthdate: "",
      cpf: "",
      rg: "",
      mobile_phone: "",
      status_id: undefined,
      marital_status_id: undefined,
      type_id: undefined,
      street_name: "",
      building_number: "",
      post_code: "",
      complement: "",
      neighborhood: "",
      city_id: undefined,
      state_id: undefined,
      country_id: undefined,
      authorizations: [],
      regional_id: undefined,
    };
    this.optionsCity = [];
  }

  @Watch("user_id")
  async User(): Promise<void> {
    if (this.user_id) {
      this.loading = true;
      let user = await UserFetchOneRequest(this.user_id);
      this.changeState(Number(user.user_address.address.state_id));
      let userFormatedPost = UserListToPostRequest(user);
      this.form = userFormatedPost;
      this.loading = false;
    } else {
      this.reset();
    }
  }

  @Watch("open_modal", { deep: true })
  async openModal(): Promise<void> {
    if (this.open_modal) {
      await this.requestStatus();
      await this.requestTypes();
      await this.requestMaritalStatus();
      await this.requestGender();
      await this.requestState();
      await this.requestAuthorizations();
      await this.requestRegionals();
    } else {
      this.authorizationsOptions = [];
      this.authorizationsOptionsSelect = [];
      this.genderOptions = [];
      this.maritalStatusOptions = [];
      this.userTypesOptions = [];
      this.userStatusOptions = [];
      this.optionsState = [];
      this.optionsCity = [];
      this.optionsRegionals = [];
    }
  }
}
