import { IUserListRequest, IUserRequest } from "../models";

export const UserListToPostRequest = (user: IUserListRequest): IUserRequest => {
  const newAuthorizations = user.user_authorizations.map((item) => item.id);
  const formatedUser = {
    name: user.name,
    surname: user.surname,
    social_name: user.social_name,
    birthdate: user.birthdate,
    cpf: user.cpf,
    rg: user.rg,
    mobile_phone: user.mobile_phone,
    email: user.email,
    gender_id: user.gender_id,
    marital_status_id: user.marital_status_id,
    status_id: user.status_id,
    type_id: user.type_id,
    id: user.id,
    street_name: user.user_address.address.street_name,
    building_number: user.user_address.address.building_number,
    state_id: user.user_address.address.state_id,
    post_code: user.user_address.address.post_code,
    city_id: user.user_address.address.city_id,
    complement: user.user_address.address.complement,
    country_id: user.user_address.address.country_id,
    neighborhood: user.user_address.address.neighborhood,
    authorizations: newAuthorizations,
    regional_id: user.user_regional.id,
  };

  return formatedUser as IUserRequest;
};
