var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.form.id
      ? ("" + (_vm.$t('users.modal.form.title.update')))
      : ("" + (_vm.$t('users.modal.form.title.create'))),"no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":"","footer-class":"d-flex justify-content-between","size":"xl"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("users.modal.form.cancel"))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.form.id ? _vm.edit() : _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("users.modal.form.save"))+" ")])]},proxy:true}]),model:{value:(_vm.open_modal),callback:function ($$v) {_vm.open_modal=$$v},expression:"open_modal"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('b-form',[_c('b-form-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.name'),"label-class":"pb-0"}},[_c('b-form-input',{class:!_vm.$v.form.name.required && _vm.$v.form.name.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","trim":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.surname'),"label-class":"pb-0"}},[_c('b-form-input',{class:!_vm.$v.form.surname.required && _vm.$v.form.surname.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","trim":""},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.social_name'),"label-class":"pb-0"}},[_c('b-form-input',{class:!_vm.$v.form.social_name.required &&
                    _vm.$v.form.social_name.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","trim":""},model:{value:(_vm.form.social_name),callback:function ($$v) {_vm.$set(_vm.form, "social_name", $$v)},expression:"form.social_name"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.rg'),"label-class":"pb-0"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###-#'),expression:"'##.###.###-#'"}],class:!_vm.$v.form.rg.required && _vm.$v.form.rg.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","type":"text","trim":""},model:{value:(_vm.form.rg),callback:function ($$v) {_vm.$set(_vm.form, "rg", $$v)},expression:"form.rg"}})],1)],1),_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.cpf'),"label-class":"pb-0"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],class:!_vm.$v.form.cpf.required && _vm.$v.form.cpf.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","type":"text","trim":""},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.bornDate'),"label-class":"pb-0"}},[_c('b-form-datepicker',{class:!_vm.$v.form.birthdate.required && _vm.$v.form.birthdate.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"type":"date","size":"sm","trim":"","placeholder":"","date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }},model:{value:(_vm.form.birthdate),callback:function ($$v) {_vm.$set(_vm.form, "birthdate", $$v)},expression:"form.birthdate"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.email'),"label-class":"pb-0"}},[_c('b-form-input',{class:(!_vm.$v.form.email.required || !_vm.$v.form.email.email) &&
                    _vm.$v.form.email.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"type":"email","size":"sm","trim":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.gender'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.genderOptions,"value-field":"id","text-field":"name","state":!_vm.$v.form.gender_id.required && _vm.$v.form.gender_id.$dirty
                      ? false
                      : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":_vm.form.user_id === undefined
                          ? undefined
                          : _vm.form.gender.id,"disabled":true}},[_vm._v(" "+_vm._s(_vm.genderOptions.length > 0 ? _vm.$t("users.modal.form.option") : _vm.$t("users.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.gender_id),callback:function ($$v) {_vm.$set(_vm.form, "gender_id", $$v)},expression:"form.gender_id"}})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.mobilePhone'),"label-class":"pb-0"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],class:!_vm.$v.form.mobile_phone.required &&
                    _vm.$v.form.mobile_phone.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","trim":""},model:{value:(_vm.form.mobile_phone),callback:function ($$v) {_vm.$set(_vm.form, "mobile_phone", $$v)},expression:"form.mobile_phone"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.marital_status'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.maritalStatusOptions,"value-field":"id","text-field":"name","state":!_vm.$v.form.marital_status_id.required &&
                    _vm.$v.form.marital_status_id.$dirty
                      ? false
                      : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":_vm.form.user_id === undefined
                          ? undefined
                          : _vm.form.marital_status.id,"disabled":true}},[_vm._v(" "+_vm._s(_vm.maritalStatusOptions.length > 0 ? _vm.$t("users.modal.form.option") : _vm.$t("users.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.marital_status_id),callback:function ($$v) {_vm.$set(_vm.form, "marital_status_id", $$v)},expression:"form.marital_status_id"}})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.userTypesOptions'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.userTypesOptions,"value-field":"id","text-field":"name","state":!_vm.$v.form.type_id.required && _vm.$v.form.type_id.$dirty
                      ? false
                      : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":_vm.form.user_id === undefined ? undefined : _vm.form.type_id,"disabled":true}},[_vm._v(" "+_vm._s(_vm.userTypesOptions.length > 0 ? _vm.$t("users.modal.form.option") : _vm.$t("users.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.type_id),callback:function ($$v) {_vm.$set(_vm.form, "type_id", $$v)},expression:"form.type_id"}})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.userStatusOptions'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.userStatusOptions,"value-field":"id","text-field":"name","state":!_vm.$v.form.status_id.required && _vm.$v.form.status_id.$dirty
                      ? false
                      : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":_vm.form.user_id === undefined
                          ? undefined
                          : _vm.form.status.id,"disabled":true}},[_vm._v(" "+_vm._s(_vm.userStatusOptions.length > 0 ? _vm.$t("users.modal.form.option") : _vm.$t("users.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.status_id),callback:function ($$v) {_vm.$set(_vm.form, "status_id", $$v)},expression:"form.status_id"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.zipCode'),"label-class":"pb-0"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],class:!_vm.$v.form.post_code.required && _vm.$v.form.post_code.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"type":"text","size":"sm","trim":""},model:{value:(_vm.form.post_code),callback:function ($$v) {_vm.$set(_vm.form, "post_code", $$v)},expression:"form.post_code"}})],1)],1),_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.streetName'),"label-class":"pb-0"}},[_c('b-form-input',{class:!_vm.$v.form.street_name.required &&
                    _vm.$v.form.street_name.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","trim":""},model:{value:(_vm.form.street_name),callback:function ($$v) {_vm.$set(_vm.form, "street_name", $$v)},expression:"form.street_name"}})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.buildNumber'),"label-class":"pb-0"}},[_c('b-form-input',{class:!_vm.$v.form.building_number.required &&
                    _vm.$v.form.building_number.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","trim":""},model:{value:(_vm.form.building_number),callback:function ($$v) {_vm.$set(_vm.form, "building_number", $$v)},expression:"form.building_number"}})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.addressComplement'),"label-class":"pb-0"}},[_c('b-form-input',{class:!_vm.$v.form.complement.required && _vm.$v.form.complement.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"type":"text","size":"sm","trim":""},model:{value:(_vm.form.complement),callback:function ($$v) {_vm.$set(_vm.form, "complement", $$v)},expression:"form.complement"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.state'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.optionsState,"value-field":"id","text-field":"name","state":!_vm.$v.form.state_id.required && _vm.$v.form.state_id.$dirty
                      ? false
                      : null},on:{"change":function($event){return _vm.changeState(_vm.form.state_id)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":_vm.form.user_id === undefined ? undefined : _vm.form.state_id,"disabled":true}},[_vm._v(" "+_vm._s(_vm.optionsState.length > 0 ? _vm.$t("users.modal.form.option") : _vm.$t("users.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.state_id),callback:function ($$v) {_vm.$set(_vm.form, "state_id", $$v)},expression:"form.state_id"}})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.city'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.optionsCity,"value-field":"id","text-field":"name","disabled":_vm.optionsCity.length > 0 || _vm.user_id
                      ? false
                      : true || this.user_id == undefined,"state":_vm.optionsCity.length > 0
                      ? !_vm.$v.form.city_id.required && _vm.$v.form.city_id.$dirty
                        ? false
                        : null
                      : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":_vm.form.user_id === undefined ? undefined : _vm.form.city_id,"disabled":true}},[_vm._v(" "+_vm._s(_vm.$t("users.modal.form.option"))+" ")])]},proxy:true}]),model:{value:(_vm.form.city_id),callback:function ($$v) {_vm.$set(_vm.form, "city_id", $$v)},expression:"form.city_id"}})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.neighborhood'),"label-class":"pb-0"}},[_c('b-form-input',{class:!_vm.$v.form.neighborhood.required &&
                    _vm.$v.form.neighborhood.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"type":"text","size":"sm","trim":""},model:{value:(_vm.form.neighborhood),callback:function ($$v) {_vm.$set(_vm.form, "neighborhood", $$v)},expression:"form.neighborhood"}})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.regional'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.optionsRegionals,"value-field":"id","text-field":"name","state":!_vm.$v.form.regional_id.required &&
                    _vm.$v.form.regional_id.$dirty
                      ? false
                      : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":_vm.form.user_id === undefined
                          ? undefined
                          : _vm.form.regional_id,"disabled":true}},[_vm._v(" "+_vm._s(_vm.optionsRegionals.length > 0 ? _vm.$t("users.modal.form.option") : _vm.$t("users.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.regional_id),callback:function ($$v) {_vm.$set(_vm.form, "regional_id", $$v)},expression:"form.regional_id"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.modal.form.labels.authorizations'),"label-class":"pb-0"}},[_c('b-overlay',{attrs:{"show":_vm.authorizationsOptionsSelect.length === 0,"rounded":"sm","spinner-small":""}},[_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-1","value-field":"id","text-field":"name","options":_vm.authorizationsOptionsSelect,"state":!_vm.$v.form.authorizations.required &&
                      _vm.$v.form.authorizations.$dirty
                        ? false
                        : null},model:{value:(_vm.form.authorizations),callback:function ($$v) {_vm.$set(_vm.form, "authorizations", $$v)},expression:"form.authorizations"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }