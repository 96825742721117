
import { Component, Vue, Watch } from "vue-property-decorator";
import UserForm from "@/views/user/user-form/user-form.vue";
import {
  UserList,
  // UserProfession,
  UserRemoveRequest
} from "@/services/api/ApiUser";
import QrcodeVue from "qrcode.vue";
import IUserListRequest from "@/services/models/user/IUserListRequest";
// import IUserProfession from "@/services/models/IUserProfession";
import ICity from "@/services/models/ICity";
import {
  notificationSuccess,
  notificationError
} from "@/utils/NotificationUtils";

@Component({
  components: {
    UserForm,
    QrcodeVue
  }
})
export default class User extends Vue {
  loading = false;
  openModal = false;
  items: IUserListRequest[] = [];
  open_qr_modal = false;
  user_id: number | null = null;
  totalRows = 1;
  currentPage = 1;
  perPage = 10;
  pageOptions: number[] = [10, 25, 50, 100];
  filter = "";
  countPage = 0;
  optionsCity: ICity[] = [];
  selected_user = {};
  fields: { key: string; label: string }[] = [
    {
      key: "name",
      label: `${this.$t("users.fields.name")}`
    },
    {
      key: "email",
      label: `${this.$t("users.fields.email")}`
    },
    {
      key: "cpf",
      label: `${this.$t("users.fields.cpf")}`
    },
    {
      key: "type",
      label: `${this.$t("users.fields.type")}`
    },
    {
      key: "actions",
      label: `${this.$t("users.fields.actions")}`
    }
  ];

  created(): void {
    // this.listProfessions();
  }

  openQrModal(data: IUserListRequest): void {
    this.selected_user = { id: data.id, name: data.name + " " + data.surname };
    this.open_qr_modal = true;
  }

  closeQrModal(): void {
    this.open_qr_modal = false;
  }

  mounted(): void {
    this.listUsers();
    this.totalRows = this.items.length;
  }

  async listUsers(): Promise<void> {
    this.loading = true;
    try {
      let users = await UserList();
      this.items = users;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  // async listProfessions(): Promise<void> {
  //   let professions = await UserProfession();
  //   this.professions = professions;
  // }

  fullname(values: IUserListRequest): string {
    return `${values.name} ${values.surname}`;
  }

  formataCPF(value: string): string {
    let newcpf = value.replace(/[^\d]/g, "");
    return newcpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  showModal(): void {
    this.openModal = true;
    this.user_id = null;
  }

  closeModal(): void {
    this.openModal = false;
  }

  async showEditModal(id: number): Promise<void> {
    try {
      this.user_id = id;
      this.openModal = true;
    } catch (error) {
      console.error(error);
      notificationError(
        `${this.$t("users.messages.error.message.searchUser")}`
      );
    }
  }

  async removeUser(id: number): Promise<void> {
    this.loading = true;
    try {
      await UserRemoveRequest(id);
      let filter = this.items.filter((item) => item.id !== id);
      this.items = filter;
      notificationSuccess("Usuário removido!");
    } catch (error) {
      console.error(error);
      notificationError("Não foi possível remover usuário.");
    } finally {
      this.loading = false;
    }
  }

  showPages(): number {
    return Math.ceil(this.totalRows / this.perPage);
  }

  onFiltered(filteredItems: IUserListRequest[]): void {
    if (filteredItems.length > 0) {
      this.countPage = Math.ceil(this.totalRows / this.perPage);
    } else {
      this.countPage = 1;
    }
  }

  reloadPage(): void {
    this.listUsers();
  }

  @Watch("items")
  watchItems(newValue: IUserListRequest[]): void {
    this.items = newValue;
    this.totalRows = this.items.length;
  }
}
